// |------------------------------------------------------
// |------------------------------------------------------
// | Header
// |------------------------------------------------------
// |------------------------------------------------------

.header
	position: fixed
	left: 0
	right: 0
	top: 0
	z-index: 5
	background: $white-bis
	border-bottom: 1px solid $grey-lighter
	// box-shadow: $shadow
	@include desktop
		display: none
	@include touch
		+ div
			margin-top: 65px
	&__wrapper
		display: flex
		justify-content: space-between
		align-items: center
		height: 65px
		padding-left: spacing()
		.icon-button
			margin-right: spacing()
		.logo
			margin: 10px	 0 0 0
		// 	width: 100px
		// 	svg
		// 		max-width: 100%
	&__overlay
		visibility: hidden
		position: fixed
		top: 0
		right: 0
		bottom: 0
		left: 0
		z-index: 7
		opacity: 0
		transition: $transition-base
		background: rgba($black, 0.6)
		// pointer-events: none
		&.opened
			visibility: visible
			opacity: 1
