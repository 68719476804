// |------------------------------------------------------
// |------------------------------------------------------
// | Filter
// |------------------------------------------------------
// |------------------------------------------------------

.filter
	min-height: 65px
	margin: 0 0 15px 0
	// padding-bottom: 0
	&__wrapper
		display: flex
		flex-wrap: wrap
		align-items: flex-end
		margin-bottom: spacing()
	.navigation
		display: flex
		justify-content: center
		margin: -5px 0 0 auto
		padding: 0
		@include touch
			display: none
		.info
			padding-right: 5px
			min-width: 100px
			text-align: right
	.field
		margin-right: spacing()
		margin-bottom: 0
		@include mobile
			width: 100%
			margin-right: 0
			&.is-floating-label
				margin-bottom: spacing(1.5)
		select,
		input
			// border-color: white
			min-height: 50px
			padding-left: spacing(2)
		input
			width: 100%
			@include tablet
				min-width: 330px
		select
			min-width: 170px
			// font-size: 0.8em
	.shortened
		padding-bottom: 13px
		color: $primary
		font-weight: $weight-semibold
		strong
			color: $secondary
