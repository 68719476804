// |------------------------------------------------------
// |------------------------------------------------------
// | Helpers
// |------------------------------------------------------
// |------------------------------------------------------

*:focus
    outline: none

body,
html
    background: $white-ter

html,
body,
.scroll,
.modal-content,
.modal-card-body
    &::-webkit-scrollbar-track
        background: $white-bis
    &::-webkit-scrollbar
        width: 5px
    &::-webkit-scrollbar-thumb
        background-color: $grey-lighter
        border-radius: 0.313rem

::selection
    background-color: $primary
    color: $white

.debug
    border: 1px solid red

.no-events
    pointer-events: none

.no-select
    user-select: none

.pointer
    cursor: pointer

.hidden
    display: none
    opacity: 0
    visibility: hidden

.is-ellipsis,
.multiselect__option span
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.pointer
    cursor: pointer

.help
    cursor: help

.rotate-180,
.rotate-180 svg
    transform: rotate(180deg)

// Positions
$positions: relative, absolute, fixed
@each $position in $positions
    .is-#{$position}
        position: $position

// $weight-light:      'name-Light';
// $font-semilight:    'name-SemiLight';
// $fonts: 'Light', 'SemiLight'
// @each $font in $fonts
// 	@font-face
// 		font-weight: 'name-#{$font}'
// 		src: url('~@/assets/fonts/name-#{$font}.eot')
// 		src: url('~@/assets/fonts/name-#{$font}.woff') format('woff'), url('~@/assets/fonts/name-#{$font}.ttf') format('truetype'), url('~@/assets/fonts/name-#{$font}.svg') format('svg')
// 		font-weight: normal
// 		font-style: normal

// Font Weight
$families: ('thin': $weight-thin, 'light': $weight-light, 'regular': $weight-regular, 'medium': $weight-medium, 'semibold': $weight-semibold, 'bold': $weight-bold)
@each $name, $family in $families
    .is-#{$name}
        font-weight: $family !important

// Align
$aligns: center, left, right, justify
@each $align in $aligns
    .text-#{$align}
        text-align: $align

// Text Transform
$transforms: capitalize, uppercase, lowercase, none
@each $transform in $transforms
    .text-#{$transform}
        text-transform: $transform