// |------------------------------------------------------
// |------------------------------------------------------
// | Page
// |------------------------------------------------------
// |------------------------------------------------------

.page
	padding: $block-spacing
	width: 100%
	min-height: 100vh
	@include desktop
		margin-left: 100px
		padding-left: $block-spacing * 2

	&__button
		background: transparent
		border: none
		cursor: pointer
		position: relative
		padding: 5px 15px 10px
		margin-top: 22px
		height: 40px
		z-index: 5
		color: $primary
		@include touch
			margin-top: 16px
		&--mode
			padding-top: 0
			padding-bottom: 0
			@include touch
				position: fixed
				top: 0
				right: 85px
		.svg,
		.icon
			position: relative
			width: 30px
			opacity: 0.7
			&:hover
				opacity: 1
		.icon
			opacity: 0.6
			top: -4px
		// &__icon
		// 	width: 20px
		// 	height: 20px
		// 	font-size: 8px
		// 	position: absolute
		// 	top: 0
		// 	right: 5px
		// 	background: $danger
		// 	color: $white
		// 	display: flex
		// 	justify-content: center
		// 	align-items: center
		// 	border-radius: 100%

	&__title
		max-width: 100%
		display: flex
		flex-direction: column
		justify-content: flex-start
		align-items: flex-start

	&__selection
		display: flex
		justify-content: flex-start
		align-items: center
		width: 100%
		max-width: 350px
		position: fixed
		bottom: 10px
		left: 50%
		margin-left: -115px
		padding: 7px
		border-radius: 30px
		background: $white
		z-index: 3
		box-shadow: $shadow
		@include mobile
			margin-left: -175px
		.button
			width: 100%

	&__actions
		display: flex
		align-items: center
		justify-content: flex-end
		@include desktop
			min-height: 70px
		@include touch
			padding-top: 0
			padding-bottom: 0
			justify-content: flex-end
		@include mobile
			justify-content: flex-start
			margin-bottom: $block-spacing
			.icon
				display: none

		.b-skeleton
			height: 40px
			&.is-centered
				position: relative
				top: 5px
				width: 100px
				margin-right: 1.5rem
			&.is-right
				width: 165px
				margin-top: 0
			&-item
				border-radius: 30px!important

		.button
			// line-height: 1.95
			.icon
				position: relative
				top: 2px
			.plus,
			.add-user
				top: -1px
				svg
					width: 21px
			> span
				position: relative
				top: -1px
