// |------------------------------------------------------
// |------------------------------------------------------
// | Forms
// |------------------------------------------------------
// |------------------------------------------------------

.label
	color: $primary
	font-weight: $weight-semibold
	z-index: 2!important

.field
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus
		outline: none
		box-shadow: 0 0 0 1000px $white inset
	.textarea
		min-height: 6em

	.input
		box-shadow: none
		font-weight: $weight-light
		background: $white
		&:not(.is-small, .is-semibold, .is-large)
			padding-top: 1.5em
			padding-bottom: 1.5em
			padding-right: 1.5em

	.select
		display: block
		&:not(.is-multiple, .is-small, .is-semibold, .is-large)
			height: 3em
		&:after
			margin-top: -2px
		select
			width: 100%
			height: 100%
	
	.input,
	.select
		&:disabled
			background: $white-ter

	.help
		display: none
		position: absolute
		color: $white
		background: $danger
		border-radius: 4px
		right: 15px
		bottom: -15px
		white-space: nowrap
		z-index: 10
		&.is-danger,
		&.is-success
			padding: 5px 10px
		&.is-danger
			display: block
			background: $danger
		&.is-success
			background: $success

	.icon
		height: 60px

	&.preview
		// margin-top: -10px
		.image,
		.icon
			display: inline-flex
			justify-content: center
			align-items: center
			position: relative
			border: 1px solid $grey-lighter
			overflow: hidden
			border-radius: 10px
			width: 100%
			height: 80px
			&:hover
				border-color: $grey-light
			&.is-valid
				border-color: $success
			img
				display: block
				border-radius: $radius
		.image
			background: url('./assets/images/no-image.png') center center no-repeat
			background-size: auto 40px
			img
				position: absolute
				left: 0
				top: 50%
				background: $white
				transform: translateY(-50%)
		.icon
			width: 42px
			height: 42px
			margin-top: 3px
			border: 1px solid #ccc
			img
				max-width: 95%
				max-height: 95%
		.input
			position: absolute
			top: 0
			right: 0
			bottom: 0
			left: 0
			height: 100%
			cursor: pointer
			border: 0
			opacity: 0
			text-indent: -9999px
			transition: $transition-base

	&.remove,
	&.more
		svg
			width: 20px
			cursor: pointer
			fill: $danger
			margin-left: -8px 

	&.more
		svg
			width: 25px
			fill: $success
			margin-right: -3px

	&.add
		display: block
		width: 100%
		text-align: right

		