// |------------------------------------------------------
// |------------------------------------------------------
// | Auth
// |------------------------------------------------------
// |------------------------------------------------------

@use 'sass:math'

body
	overflow: hidden

.auth
	//height: 100vh
	//height: calc(var(--vh, 1vh) * 100)
	overflow: hidden

	.logo
		margin-bottom: math.div(spacing(), 3.5)

	.columns
		margin: 0
		height: 100%

	&__image
		display: block
		height: 100%
		padding: 0
		//position: relative
		position: fixed
		width: 40%
		background: $black
		z-index: 20
		.image
			opacity: 0.8
			height: 100%
		video
			position: absolute
			right: 0
			bottom: 0
			min-width: 100%
			min-height: 100%
			object-fit: cover
			z-index: 1
			background: $black

		&::after
			display: none
			content: ''
			position: absolute
			top: 0
			left: 0
			right: 0
			bottom: 0
			z-index: 5
			background: rgba($primary, 0.45)

	&__forgot
		position: absolute
		top: 2px
		right: 0
		font-size: 14px

	&__content
		position: relative
		display: flex
		justify-content: center
		align-items: center
		flex-direction: column
		height: 100%
		min-height: 100vh
		@include mobile
			max-width: 85%
			margin: 0 auto

		> span
			display: block
			width: 100%

		.loading-overlay .loading-background
			background: transparent
			backdrop-filter: blur(8px)
			-webkit-backdrop-filter: blur(8px)


	&__form
		display: block
		max-width: 400px
		margin: 0 auto
		padding: 10vh 0 15px

		.icon
			opacity: 0.2
			&.has-text-success,
			&.has-text-danger
				opacity: 1

		.input
			border: 2px solid $white
			height: 3em
			transition: $transition-base
			&:focus
				border-color: $grey-light

		.button
			height: auto
			padding: 0.8em 2.5em

	&__or
		display: block
		position: relative
		margin: 15px 0 35px
		text-align: center
		border-bottom: 1px solid $grey-lighter
		span
			position: relative
			top: 10px
			background: $white-ter
			padding: 0 15px
			user-select: none

	&__buttons
		display: flex
		justify-content: center
		align-items: center

	&__social
		font-size: 1em
		background: transparent
		color: $primary
		display: flex
		align-items: center
		justify-content: center
		position: relative
		margin: 0 spacing(3)
		border: 0
		width: 45px
		height: 45px !important
		padding: 0 !important
		border-radius: 100%
		transition: $transition-base
		cursor: pointer
		border: 2px solid $primary
		.icon
			opacity: 1
			position: relative
			margin-top: 3px
			svg
				width: 20px
		&:hover
			svg
				fill: $white
		@each $name, $color in $socials
			&.#{$name}
				color: $color
				border-color: $color
				&.is-loading,
				&:hover
					color: $white
					background: $color
		&.linkedin svg
			width: 35px
		&.gmail svg
			width: 15px
